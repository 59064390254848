import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastr } from 'react-redux-toastr'
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export const LeaveRequest = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const navigate = useNavigate();
    const [employee, setEmployee] = useState<Employee>();
    const [from, setFrom] = useState<any>();
    const [to, setTo] = useState<any>();
    const [leaveType, setLeaveType] = useState<any>(1);
    const [reason, setReason] = useState<string>("");
    const [leaveTypes, setLeaveTypes] = useState<any[]>();
    const [leaveBalances, setLeaveBalances] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setEmployee(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        setFrom(moment().format('YYYY-MM-DD'));
        setTo(moment().format('YYYY-MM-DD'));
    }, []);

    useEffect(() => {
        loadLeaveBalancess();
        /*ServerGateway
            .getLeaveTypes()
            .then((data) => {
                setLeaveTypes(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });*/
    }, [employee]);

    const requestLeave = () =>{
        setIsLoading(true);
        let leaveRequest = {
            employeeId: employee?.id,
            from: from,
            isFirstDayHalfDay: false,
            to: to,
            isLastDayHalfDay: false,
            leaveType: {
                id: leaveType
            },
            reason: reason,
            updatedBy: employee?.id,
            status: 'Requested'
        };

        ServerGateway
            .postLeave(leaveRequest)
            .then((data) => {
                toastr.error("Success", "Leave request created successfully");
                navigate("/leave");
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);                
                toastr.error("Error", "Error creating the leave request");
                setIsLoading(false);
            });
    }

    const loadLeaveBalancess = () => {
        setIsLoading(true);
        ServerGateway
            .getLeaveBalance(employee?.id)
            .then((data) => {
                setLeaveBalances(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    return (
        <div className="page-payslip">
            <div className='container'>
                <div className='row' style={{ marginTop: "20px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <>
                            <div className="form-group">
                                <label htmlFor="from">From</label>
                                <input type="date" className="form-control" id="from" value={from} onChange={(e) => setFrom(e.target.value)}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="to">To</label>
                                <input type="date" className="form-control" id="to" value={to} onChange={(e) => setTo(e.target.value)}></input>
                            </div>
                            <div className="form-group">
                                <label htmlFor="leavetype">Leave Type</label>
                                <select className="form-control" id="leavetype" value={leaveType} onChange={(e) => setLeaveType(e.target.value)}>
                                    {/*leaveTypes?.map((leaveType) => (
                                        <option value={leaveType.id} key={leaveType.id}>{leaveType.name}</option>
                                    ))*/}
                                    {leaveBalances && leaveBalances.map((balance) => (
                                        <>
                                            {balance.balance > 0 &&
                                                <option value={balance.leaveTypeId} key={balance.leaveTypeId}>{balance.leaveTypeName} ({balance.balance})</option>
                                            }
                                        </>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="reason">Reason</label>
                                <textarea className="form-control" id="reason" value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
                            </div>
                            {(reason?.length > 0) &&
                                <div className="btn bg-primary" onClick={requestLeave}>
                                    Request
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}