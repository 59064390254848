import { AppSettings } from "./Models";
import ServerGateway from "./ServerGateway";

const SettingsService = {
    get: async function () {
        let settings_data = localStorage.getItem("settings");
        if (settings_data && settings_data.length > 0) {
            return JSON.parse(settings_data);
        }
        let data = await ServerGateway.getCompanySettings();

        let settings = data.appSetting;
        localStorage.setItem("settings", JSON.stringify(settings));
        return settings;
    }
}

export default SettingsService;
