import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Employee } from 'service/Models';
import ServerGateway from 'service/ServerGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export const Payslip = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [employee, setEmployee] = useState<Employee>();
    const [month, setMonth] = useState<any>();
    const [payslip, setPayslip] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user.profile) {
            setEmployee(user.profile.profile);
        }
    }, [user]);

    useEffect(() => {
        if (month) {
            setIsLoading(true);
            var month_data = moment(month + "-01")
            ServerGateway
                .getPayslip(employee?.id, moment(month_data).year(), (moment(month_data).month() + 1))
                .then((data) => {
                    console.log(data);
                    if (data.length > 0) {
                        console.log("Seting payslip data")
                        setPayslip(data[0]);
                    }
                    else {
                        setPayslip(undefined);
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [month]);

    useEffect(() => {
        setMonth(moment().subtract(1, 'months').startOf('month').format('YYYY-MM'));
    }, []);

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    }

    const getDownloadLink = (id) => {
        let url = `${process.env.REACT_APP_PUBLIC_URL || ''}`;
        url += "/voucher/viewer/payslip/download/" + id;
        url += "?companyCode="+ localStorage.getItem("companyCode");
        return url;
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <div className="page-payslip">
            <div className='container'>
                <div className='row'>
                    <input type="month" className="form-control" value={month} onChange={handleMonthChange}  style={{ textAlign: "center" }}></input>
                </div>
                <div className='row' style={{ marginTop: "20px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    {!isLoading &&
                        <>
                            {!payslip &&
                                <div>Payslip information not available</div>
                            }
                            {payslip &&
                                <>
                                    <div className='card'>
                                        <table style={{ textAlign: "center", margin: "1rem" }} className="table">
                                            <tr>
                                                <td style={{ width: "33%" }}>
                                                    Working Days
                                                    <div className="fw-bold">{payslip?.calenderDays}</div>
                                                </td>
                                                <td style={{ width: "33%" }}>
                                                    Worked Days
                                                    <div className="fw-bold">{payslip?.workingDays}</div>
                                                </td>
                                                <td style={{ width: "33%" }}>
                                                    LOP
                                                    <div className="fw-bold">{payslip?.lop}</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className='card earnings'>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item bg-dark">
                                                Total Earnings
                                                <div className="fw-bold">{currencyFormat(payslip.totalEarnings)}</div>
                                            </li>
                                            {payslip && payslip.details.map((detail) => (
                                                <>
                                                    {!detail.salaryComponent.isDeduction &&
                                                        <li className="list-group-item">
                                                            {detail.salaryComponent.name}
                                                            <div className="fw-bold">{currencyFormat(detail?.amount)}</div>
                                                        </li>
                                                    }
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className='card deductions'>
                                        <ul className="list-group list-group-flush">

                                            <li className="list-group-item bg-dark">
                                                Total Deductions
                                                <div className="fw-bold">{currencyFormat(payslip.totalDeductions)}</div>
                                            </li>
                                            {payslip && payslip.details.map((detail) => (
                                                <>
                                                    {detail.salaryComponent.isDeduction &&
                                                        <li className="list-group-item">
                                                            {detail.salaryComponent.name}
                                                            <div className="fw-bold">{currencyFormat(detail?.amount)}</div>
                                                        </li>
                                                    }
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className='card net'>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item bg-dark">
                                                Net Salary
                                                <div className="fw-bold">{currencyFormat(payslip.netPayable)}</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <a className='btn bg-primary' target="_blank" href={getDownloadLink(payslip.id)} download>
                                        <FontAwesomeIcon icon={faDownload} style={{ marginRight: 10 }} />
                                        Download
                                    </a>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}